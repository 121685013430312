<script>
import PageHeader from "@/components/page-header";
import InvoiceTable from "@/views/pages/invoice/table"
export default {
  components: {
    PageHeader,
    InvoiceTable
  },
  data() {
    return {
      title: this.$t('orders.delivery'),
    };
  },
  watch: {
    $route (to,from){
      if (to.name == "invoice" && from.name == "platform/invoice") {
          window.location.reload(true);
      }
      if (to.name == "delivery" && from.name == "platform/delivery") {
          window.location.reload(true);
      }
    
    }
  },
  methods: {
    configureDataNextModule: function (id) {
      if (this.$route.query.data) {
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.platformId = id
        return btoa(JSON.stringify(rs))
      }
    },
    configureLinkNextModule: function (url) {
      if (this.$route.path.includes("platform")) {
        return "/platform/" + url
      } else {
        return url
      }
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="col-xl-12">
        <PageHeader :title="title" />
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('orders.delivery') }}</h4>
              <p class="card-title-desc">
              </p>

              <InvoiceTable :type="'delivery'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
</div></template>